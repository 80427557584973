import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import MiniHdImageQuery from "../components/MiniHdImageQuery"
import Head from "../components/Head"
import HeroImage from "../images/mini-hd-walk-behind-header.jpg"
import HeroHeader from "../components/HeroHeader"
import WalkieScrubberImages from "../components/WalkieScrubbersImages"
import WalkieSweepersImages from "../components/WalkieSweepersImages"
import RideOnFloorScrubberImages from "../components/RideOnFloorScrubberImages"
import RideOnFloorSweeperImages from "../components/RideOnFloorSweeperImages"
import SEO from "../components/SEO"
import WalkBehindScrubbers from "../components/machineImageComponents/WalkBehindScrubbers"
import WalkBehindSweepers from "../components/machineImageComponents/WalkBehindSweepers"
import RideOnFloorScrubbers from "../components/machineImageComponents/RideOnFloorScrubbers"
import RideOnFloorSweepers from "../components/machineImageComponents/RideOnFloorSweepers"

const StyledNavContainer = styled.div`
  max-width: 1400px;
  margin: 4rem auto;
  display: flex;
  justify-content: space-evenly;
`
const Button = styled.button`
  padding: 0.5rem;
  background: transparent;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: red;
    color: white;
    transition-duration: 0.3s;
  }
`

const ImagePage = () => (
  <Layout>
    <SEO title="images" />
    <Head title="images" />
    {/* <HeroHeader
      heroImage={HeroImage}
      title="FactoryCat Image Gallery"
      subHeading="Browse our gallery of images"
      buttonTitle="See Images"
      goTo="#walkie-scrubbers"
      fontSize="2em"
    /> */}

    <div id="walkie-scrubbers"></div>
    <WalkBehindScrubbers />
    <div id="walkie-sweepers"></div>
    <WalkBehindSweepers />
    <div id="ride-on-scrubbers"></div>
    <RideOnFloorScrubbers />
    <div id="ride-on-sweepers"></div>
    <RideOnFloorSweepers />
  </Layout>
)

export default ImagePage
